body {
  background: #1b1b1d;
  color: #eee;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  background: #1b1b1d;
}
span, a {
  color: #21ce99;
  &.plain {
    color: #eee;
  }
}
a:hover {
  color: #199d75;
}

.muted {
  color: rgba(255,255,255,.25);
}

div [class*="left floated"] {
  float: left;
  margin-left: 0.25em;
}

div [class*="right floated"] {
  float: right;
  margin-right: 0.25em;
}
span [class*="right floated"] {
  float: right;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.ui.message:not(.negative):not(.info) {
  background: rgba(0,0,0,.87);
  padding: 1em 1.5em;
  line-height: 1.4285em;
  color: #f8f8f9;
  border: 1px solid rgba(248, 248, 249, 0.8);
}

.clearfix:before,
.clearfix:after { content: ''; display: table; }
.clearfix:after { clear: both; }

// Helpers

$class: m;
$directions: r, l, t, b;
$direction-names: right, left, top, bottom;

@for $i from 0 through 4 {

  //@each $direction in $directions {
  @for $d from 1 through length($directions) {
    // for each $col_#{i}
    .#{$class}#{nth($directions,$d)}-#{$i} {
      margin-#{nth($direction-names, $d)}: #{$i}em;
    }
  }
}


.profile .ui.toggle.checkbox .box:before,
.profile .ui.toggle.checkbox label:before,
.profile .ui.toggle.checkbox input:focus~.box:before,
.profile .ui.toggle.checkbox input:focus~label:before {
  background-color: rgba(150,150,150,.7);
}
.profile .ui.toggle.checkbox .box:hover::before,
.profile .ui.toggle.checkbox label:hover::before {
  background-color: rgba(150,150,150,.5);
}

.profile .ui.toggle.checkbox input:checked~.box:before,
.profile .ui.toggle.checkbox input:checked~label:before {
  background-color: #21ce99 !important;
  box-shadow: inset 0 0 5px rgba(0,0,0,.8);
}
.profile .ui.toggle.checkbox input:checked~.box:after,
.profile .ui.toggle.checkbox input:checked~label:after {

}