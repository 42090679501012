.main-logo {
  width: 100px;
  height: 29px;
}
.title-col .sub.header {
  font-size: .65em !important;
  font-weight: bold !important;
  text-transform: uppercase;
  color: #a3a3a3 !important;
}
.title-col {
  display: -webkit-flex !important;
  display: flex !important;
  text-align: left;
}
.title-col .ui.header {
  -webkit-flex: auto;
          flex: auto;
}
.user-status-container {
  margin-top: 2em;
  -webkit-flex: 0 1;
          flex: 0 1;
}
.title-col .ui.icon.header {
  text-align: left;
}
.title-col .ui.header {
  margin-top: 2em;
}
h3.ui.header,
h3.ui.header .sub.header,
h4.ui.header,
h4.ui.header .sub.header {
  color: #FFF;
}
.chart-col {
    max-height: 500px;
    min-height: 280px;
}
.main-tabs-col > div {
}
.main-tabs-col .ui.tabular.menu .item {
  color: #d9d9d9;
}
.main-tabs-col .ui.tabular.menu .active.item {
  background-color: #1b1b1d;
  border: 1px solid #0e0d0d;
  border-bottom: transparent;
  color: #EEE;
  position: relative;
  z-index: 10;


  box-shadow: 0 -4px 4px 1px rgba(0, 0, 0, 0.01), 0 -10px 10px rgba(0, 0, 0, 0.3);

}
.main-tabs-col .ui.attached.segment {
  border-color: #0e0d0d;
}
.main-tabs-col .ui.tabular.menu {
  border-bottom: 1px solid #0e0d0d;
}
.main-tabs-col .ui.segment.tab {
  height: 90%;
  padding: 0;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.01), 0 3px 24px rgba(0, 0, 0, 0.6);
}

.ui.styled.accordion {
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.01), 0 3px 24px rgba(0, 0, 0, 0.6);
  background-color: #1b1b1d;
  border: 1px solid #0e0d0d;
}
.ui.styled.accordion .title {
  color: rgba(255,255,255,.65);
}
.ui.styled.accordion .title span {
  color: rgba(33,206,153,.65)
}
.ui.styled.accordion .active.title {
  color: #FFF;
}
.ui.styled.accordion .title:hover span,
.ui.styled.accordion .active.title span {
  color: rgba(33,206,153,1)
}
.ui.styled.accordion .title:hover {
  color: #21ce99;
}
.ui.ordered.list .list>.item:before, .ui.ordered.list>.item:before, ol.ui.list li:before {
  color: rgba(255,255,255,.55);
}
.ui.segments {
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.01), 0 3px 24px rgba(0, 0, 0, 0.6);
  border: 1px solid #0e0d0d;
}
.ui.segment {
  background-color: #1b1b1d;
}
.ui.segment.user-owned {
  color: #FFF;
  font-weight: bold;
}
.main-tabs-col .ui.segments,
.main-tabs-col .ui.accordion {
    border: none;
    box-shadow: none;
}
.ui.green.button, .ui.green.buttons .button {
  background-color: rgba(33,206,153,1);
}
.ui.green.button:hover,
.ui.green.button:active,
.ui.green.button:focus,
.ui.green.buttons .button:hover {
  background-color: rgba(33,206,153,1);
}
.ui.green.button.inverted {
  background-color: transparent;
  box-shadow: 0 0 0 2px rgba(33,206,153,1) inset!important;
  color: rgba(33,206,153,1);
}
.ui.green.button.inverted:hover,
.ui.green.button.inverted:active,
.ui.green.button.inverted:focus {
  background-color: rgba(33,206,153,1);
}
.ui.basic.green.label {
  background: none #000!important;
  color: rgba(33,206,153,1) !important;
  border-color: rgba(33,206,153,1)  !important;;
}
.ui.basic.green.labels a.label:hover, a.ui.basic.green.label:hover {
  background: none #000!important;
  color: rgba(33,206,153,1) !important;
  border-color: rgba(33,206,153,1)  !important;;
}
.ui.header .button .icon {
  margin-right: 0;
}
.header-text {
  float: left;
}

.hot-0 {
  color: rgba(255,0,0,1);
}
.hot-1 {
  color: rgba(255,50,0,1);
}
.hot-2 {
  color: rgba(255,100,0,1);
}
.hot-3 {
  color: rgba(255,150,0,1);
}
.hot-4 {
  color: rgba(255,200,0,1);
}
.page-footer {
  background-color: #1b1b1d;
  border: 1px solid #0e0d0d;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.01), 0 3px 24px rgba(0, 0, 0, 0.6);
  margin-top: 3em;
  padding: 2.5em 0;
}
.page-footer a {
  color: rgba(33,206,153,1);
}

@media only screen and (max-width: 767px) {
  .header-text {
    float: none;
  }
.refresh-button {
  float: none !important;
  display: block;
}
}

.ui.dimmer {
  background-color: transparent;
}
.ui.styled.accordion .title.icon-legend span {
  color: #FFF; }

.homers-in-last-game {
  color: #00aa00 !important; }

.games-since-last {
  color: red !important; }

.player-link {
  color: #eee;
  text-decoration: underline;
  display: inline;
  background: none;
  border: none;
  outline: none;
  padding-top: 0;
  padding-bottom: 0; }
  .player-link:hover, .player-link:focus {
    color: #DDD; }

.player-link:hover {
  color: #fff;
  text-decoration: none; }

.selected-player a {
  font-weight: bold; }

.player-homers {
  float: right;
  text-align: right;
  width: 33%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }
  .player-homers .inactive-hrs {
    -webkit-flex: 0 0 3.2em;
            flex: 0 0 3.2em; }

.player-stats {
  display: none; }

.selected-player .player-stats {
  display: block; }

.selected-player .slim-stats {
  display: none; }

.player-inactive {
  opacity: .5; }

.ui.list .player-stats {
  font-size: .9em; }

.ui.list .player-stats li:first-child {
  font-weight: bold; }

.ui.styled.accordion .user-owned .title {
  color: #FFF; }

.profile .ui.divider {
  color: #d5d5d5;
  margin-top: 2em; }

.profile .player-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

body {
  background: #1b1b1d;
  color: #eee;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html {
  background: #1b1b1d; }

span, a {
  color: #21ce99; }
  span.plain, a.plain {
    color: #eee; }

a:hover {
  color: #199d75; }

.muted {
  color: rgba(255, 255, 255, 0.25); }

div [class*="left floated"] {
  float: left;
  margin-left: 0.25em; }

div [class*="right floated"] {
  float: right;
  margin-right: 0.25em; }

span [class*="right floated"] {
  float: right; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.ui.message:not(.negative):not(.info) {
  background: rgba(0, 0, 0, 0.87);
  padding: 1em 1.5em;
  line-height: 1.4285em;
  color: #f8f8f9;
  border: 1px solid rgba(248, 248, 249, 0.8); }

.clearfix:before,
.clearfix:after {
  content: '';
  display: table; }

.clearfix:after {
  clear: both; }

.mr-0 {
  margin-right: 0em; }

.ml-0 {
  margin-left: 0em; }

.mt-0 {
  margin-top: 0em; }

.mb-0 {
  margin-bottom: 0em; }

.mr-1 {
  margin-right: 1em; }

.ml-1 {
  margin-left: 1em; }

.mt-1 {
  margin-top: 1em; }

.mb-1 {
  margin-bottom: 1em; }

.mr-2 {
  margin-right: 2em; }

.ml-2 {
  margin-left: 2em; }

.mt-2 {
  margin-top: 2em; }

.mb-2 {
  margin-bottom: 2em; }

.mr-3 {
  margin-right: 3em; }

.ml-3 {
  margin-left: 3em; }

.mt-3 {
  margin-top: 3em; }

.mb-3 {
  margin-bottom: 3em; }

.mr-4 {
  margin-right: 4em; }

.ml-4 {
  margin-left: 4em; }

.mt-4 {
  margin-top: 4em; }

.mb-4 {
  margin-bottom: 4em; }

.profile .ui.toggle.checkbox .box:before,
.profile .ui.toggle.checkbox label:before,
.profile .ui.toggle.checkbox input:focus ~ .box:before,
.profile .ui.toggle.checkbox input:focus ~ label:before {
  background-color: rgba(150, 150, 150, 0.7); }

.profile .ui.toggle.checkbox .box:hover::before,
.profile .ui.toggle.checkbox label:hover::before {
  background-color: rgba(150, 150, 150, 0.5); }

.profile .ui.toggle.checkbox input:checked ~ .box:before,
.profile .ui.toggle.checkbox input:checked ~ label:before {
  background-color: #21ce99 !important;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.8); }

