.main-logo {
  width: 100px;
  height: 29px;
}
.title-col .sub.header {
  font-size: .65em !important;
  font-weight: bold !important;
  text-transform: uppercase;
  color: #a3a3a3 !important;
}
.title-col {
  display: flex !important;
  text-align: left;
}
.title-col .ui.header {
  flex: auto;
}
.user-status-container {
  margin-top: 2em;
  flex: 0;
}
.title-col .ui.icon.header {
  text-align: left;
}
.title-col .ui.header {
  margin-top: 2em;
}
h3.ui.header,
h3.ui.header .sub.header,
h4.ui.header,
h4.ui.header .sub.header {
  color: #FFF;
}
.chart-col {
    max-height: 500px;
    min-height: 280px;
}
.main-tabs-col > div {
}
.main-tabs-col .ui.tabular.menu .item {
  color: #d9d9d9;
}
.main-tabs-col .ui.tabular.menu .active.item {
  background-color: #1b1b1d;
  border: 1px solid #0e0d0d;
  border-bottom: transparent;
  color: #EEE;
  position: relative;
  z-index: 10;


  box-shadow: 0 -4px 4px 1px rgba(0, 0, 0, 0.01), 0 -10px 10px rgba(0, 0, 0, 0.3);

}
.main-tabs-col .ui.attached.segment {
  border-color: #0e0d0d;
}
.main-tabs-col .ui.tabular.menu {
  border-bottom: 1px solid #0e0d0d;
}
.main-tabs-col .ui.segment.tab {
  height: 90%;
  padding: 0;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.01), 0 3px 24px rgba(0, 0, 0, 0.6);
}

.ui.styled.accordion {
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.01), 0 3px 24px rgba(0, 0, 0, 0.6);
  background-color: #1b1b1d;
  border: 1px solid #0e0d0d;
}
.ui.styled.accordion .title {
  color: rgba(255,255,255,.65);
}
.ui.styled.accordion .title span {
  color: rgba(33,206,153,.65)
}
.ui.styled.accordion .active.title {
  color: #FFF;
}
.ui.styled.accordion .title:hover span,
.ui.styled.accordion .active.title span {
  color: rgba(33,206,153,1)
}
.ui.styled.accordion .title:hover {
  color: #21ce99;
}
.ui.ordered.list .list>.item:before, .ui.ordered.list>.item:before, ol.ui.list li:before {
  color: rgba(255,255,255,.55);
}
.ui.segments {
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.01), 0 3px 24px rgba(0, 0, 0, 0.6);
  border: 1px solid #0e0d0d;
}
.ui.segment {
  background-color: #1b1b1d;
}
.ui.segment.user-owned {
  color: #FFF;
  font-weight: bold;
}
.main-tabs-col .ui.segments,
.main-tabs-col .ui.accordion {
    border: none;
    box-shadow: none;
}
.ui.green.button, .ui.green.buttons .button {
  background-color: rgba(33,206,153,1);
}
.ui.green.button:hover,
.ui.green.button:active,
.ui.green.button:focus,
.ui.green.buttons .button:hover {
  background-color: rgba(33,206,153,1);
}
.ui.green.button.inverted {
  background-color: transparent;
  box-shadow: 0 0 0 2px rgba(33,206,153,1) inset!important;
  color: rgba(33,206,153,1);
}
.ui.green.button.inverted:hover,
.ui.green.button.inverted:active,
.ui.green.button.inverted:focus {
  background-color: rgba(33,206,153,1);
}
.ui.basic.green.label {
  background: none #000!important;
  color: rgba(33,206,153,1) !important;
  border-color: rgba(33,206,153,1)  !important;;
}
.ui.basic.green.labels a.label:hover, a.ui.basic.green.label:hover {
  background: none #000!important;
  color: rgba(33,206,153,1) !important;
  border-color: rgba(33,206,153,1)  !important;;
}
.ui.header .button .icon {
  margin-right: 0;
}
.header-text {
  float: left;
}

.hot-0 {
  color: rgba(255,0,0,1);
}
.hot-1 {
  color: rgba(255,50,0,1);
}
.hot-2 {
  color: rgba(255,100,0,1);
}
.hot-3 {
  color: rgba(255,150,0,1);
}
.hot-4 {
  color: rgba(255,200,0,1);
}
.page-footer {
  background-color: #1b1b1d;
  border: 1px solid #0e0d0d;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.01), 0 3px 24px rgba(0, 0, 0, 0.6);
  margin-top: 3em;
  padding: 2.5em 0;
}
.page-footer a {
  color: rgba(33,206,153,1);
}

@media only screen and (max-width: 767px) {
  .header-text {
    float: none;
  }
.refresh-button {
  float: none !important;
  display: block;
}
}

.ui.dimmer {
  background-color: transparent;
}