.ui.styled.accordion .title.icon-legend span {
    color: #FFF;
}
.homers-in-last-game {
    color: #00aa00 !important;
}
.games-since-last {
    color: red !important;
}
.player-link {
    color: #eee;
    text-decoration: underline;
    display: inline;
    background: none;
    border: none;
    outline: none;
    padding-top: 0;
    padding-bottom: 0;
    &:hover, &:focus {
    color: #DDD;
      }
}
.player-link:hover {
    color: #fff;
    text-decoration: none;
}
.selected-player a {
    font-weight: bold;
}
.player-homers {
    float: right;
    text-align: right;
    width: 33%;
    // background-color: blue;
    display: flex;
    justify-content: flex-end;
    .inactive-hrs {
        flex: 0 0 3.2em;
    }
}
.player-stats {
    display: none;
}
.selected-player .player-stats {
    display: block;
}
.selected-player .slim-stats {
    display: none;
}
.player-inactive {
    opacity: .5;
}
.ui.list .player-stats {
    font-size: .9em;
}
.ui.list .player-stats li:first-child {
    font-weight: bold;
}
.ui.styled.accordion .user-owned {
    .title {
        color: #FFF;
    }
}